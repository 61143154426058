// Before build CRA change server url from localhost to env var with proper path

const config = {
    SERVER_URL: "https://old-api.mathias.egildia.pl",
    GITHUB_URL: "https://github.com/Mathias007",
    GITHUB_API: "https://api.github.com/users/Mathias007/repos",
    PROJECTS_ROOT_URL: "https://projects.egildia.pl/",
    EMAIL_SERVICE_ID: "service_43w2nse",
    EMAIL_TEMPLATE_ID: "template_pspnjwf",
    EMAIL_USER_ID: "WlyR8tbTDOOJKXmDc",
    EMAIL_ACCESS_TOKEN:"H96_YCbo4TFiuk4bkr6qv",
};

export default config;
